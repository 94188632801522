import { createClient } from '@lb-lib/rpc/client.mjs';
import { SessionClient } from '../src/api/session';

export type { SessionClient };

type TokenResponse = Awaited<ReturnType<SessionClient['token']>>;
export type User = TokenResponse['user'];
export type SessionUser = TokenResponse['allSessionUsers'][0];

export let createSessionClient = (opts: {
  endpoint: string;
  client: {
    name: string;
    version: string;
  };
}) => createClient<SessionClient>(opts);
