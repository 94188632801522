import {
  Attributes,
  Avatar,
  Badge,
  Button,
  Control,
  Entity,
  Error,
  InputLabel,
  Select,
  SortableCheckList,
  Spacer,
  Text,
  Title
} from '@lb-lib/ui';
import { useMutation } from '@lb-lib/ui-data';
import { renderWithLoader } from '@loopbound-ares/components';
import { BoxLayout } from '@loopbound-ares/scenes';
import { useOrganizations } from '@loopbound-ares/state';
import { RiArrowLeftLine } from '@remixicon/react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIntegration } from '../state/integration';

export let AuthorizePage = () => {
  let [searchParams] = useSearchParams();
  let clientId = searchParams.get('client_id') ?? searchParams.get('clientId');
  let redirectUri = searchParams.get('redirect_uri') ?? searchParams.get('redirectUri');
  let scope = searchParams.get('scope');
  let state = searchParams.get('state');

  let [page, setPage] = useState<'overview' | 'select_organization'>('overview');

  let integration = useIntegration(
    clientId ? { clientId, redirectUri: redirectUri!, scope: scope! } : null
  );

  let orgs = useOrganizations();
  let [orgId, setOrgId] = useState<string | null>(null);
  let [instanceAccess, setInstanceAccess] = useState<'all_instances' | 'multiple_instances'>(
    'all_instances'
  );
  let [selectedInstances, setSelectedInstances] = useState<string[]>([]);

  let action = useMutation(integration.mutators.action);

  let instances =
    orgs.data
      ?.find(org => org.organization.id == orgId)
      ?.projects.filter(p => {
        let wsp = orgs.data
          .flatMap(o => o.workspaces.flatMap(w => w.productWorkspaces))
          .find(w => w.id == p.workspaceProductId);
        return wsp?.product == integration.data?.product?.id;
      })
      .flatMap(p => p.instances.map(i => ({ ...i, project: p })))
      .sort((a, b) => a.project.name.localeCompare(b.project.name)) ?? [];

  return (
    <BoxLayout withAccountManagement={false} selectAccountWithFullPath>
      {renderWithLoader({ integration })(() => (
        <>
          {page != 'overview' && (
            <>
              <Button
                onClick={() => setPage('overview')}
                variant="outline"
                size="1"
                iconLeft={<RiArrowLeftLine />}
              >
                Back
              </Button>
              <Spacer height="5px" />
            </>
          )}

          <Title as="h1" size="5" weight="strong">
            Connect to {integration.data?.name}.
          </Title>

          <Spacer height="15px" />

          {page == 'overview' && (
            <>
              <Text>
                {integration.data?.name} wants to connect to {integration.data?.product?.name}.
                Choose which organization and instances you want to connect.
              </Text>

              <Spacer height="15px" />

              <Entity.Wrapper>
                <Entity.Content>
                  <Entity.Field
                    prefix={<Avatar entity={integration.data?.owner!} />}
                    title={
                      <>
                        {integration.data?.owner.isLoopbound && (
                          <>
                            <Badge color="blue" size="1">
                              Verified
                            </Badge>
                            <Spacer height={4} />
                          </>
                        )}

                        <span>{integration.data?.owner.name}</span>
                      </>
                    }
                    description={!integration.data?.owner.isLoopbound && 'Developer'}
                  />
                </Entity.Content>
              </Entity.Wrapper>

              <Spacer height="15px" />

              <InputLabel>Requested Permissions</InputLabel>
              <Spacer height="5px" />

              <Attributes
                attributes={
                  integration.data?.requestedPermissions.map(permission => ({
                    label: permission.entity,
                    content: permission.explanation
                  })) ?? []
                }
              />

              <Spacer height="15px" />

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => setPage('select_organization')}>Continue</Button>
              </div>
            </>
          )}

          {page == 'select_organization' && (
            <>
              <Text>
                Select the organization and instances you want to connect to{' '}
                {integration.data?.name}.
              </Text>

              <Spacer height="15px" />

              <Select
                label="Organization"
                value={orgId ?? ''}
                onChange={o => {
                  setOrgId(o);
                  setSelectedInstances([]);
                  setInstanceAccess('all_instances');
                }}
                items={orgs.data!.map(org => ({
                  id: org.organization.id,
                  label: org.organization.name
                }))}
              />

              {orgId && (
                <>
                  {instances.length > 0 && (
                    <>
                      <Spacer height="15px" />

                      <InputLabel>Instances</InputLabel>

                      <Spacer height="5px" />

                      <Control
                        value={instanceAccess}
                        onChange={a => {
                          setInstanceAccess(a as any);
                        }}
                        items={[
                          { id: 'all_instances', label: 'All instances' },
                          { id: 'multiple_instances', label: 'Select instances' }
                        ]}
                      />

                      {instanceAccess == 'multiple_instances' && (
                        <>
                          <Spacer height="5px" />

                          <SortableCheckList
                            sortable={false}
                            items={instances.flatMap(instance => ({
                              id: instance.id,
                              label: `${instance.project.name} - ${instance.name}`,
                              isChecked: selectedInstances.includes(instance.id)
                            }))}
                            onChange={v =>
                              setSelectedInstances(v.filter(i => i.isChecked).map(i => i.id))
                            }
                          />
                        </>
                      )}
                    </>
                  )}

                  <Spacer height="15px" />

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      disabled={
                        instanceAccess == 'multiple_instances' && selectedInstances.length == 0
                      }
                      onClick={async () => {
                        let res = await action.mutate({
                          organizationId: orgId,
                          scope: scope!,
                          state: state ?? undefined,
                          redirectUri: redirectUri!,
                          action: { type: 'accept' },
                          access:
                            instanceAccess == 'all_instances'
                              ? {
                                  type: 'organization'
                                }
                              : {
                                  type: 'instance',
                                  instanceIds: selectedInstances
                                }
                        });

                        if (res) {
                          setTimeout(() => {
                            window.location.replace(res.nextUrl);
                          }, 1000);
                        }
                      }}
                      loading={action.isLoading}
                      success={action.isSuccessPermanent}
                    >
                      Continue
                    </Button>

                    {action.error && (
                      <Error>{action.error.data?.message ?? action.error.message}</Error>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      ))}
    </BoxLayout>
  );
};
