import { createLoader } from '@lb-lib/ui-data';
import { withRpcClient } from './rpcClient';

let integrationLoader = createLoader({
  name: 'integration',

  parents: [],

  fetch: async (i: { clientId: string; redirectUri: string; scope: string }) =>
    withRpcClient(c => c.oauth.boot(i)),

  hash: i => i.clientId,

  mutators: {
    action: async (
      data: {
        organizationId: string;
        scope: string;
        state: string | undefined;
        redirectUri: string;
        action: { type: 'accept' } | { type: 'reject' };
        access:
          | { type: 'organization' }
          | {
              type: 'instance';
              instanceIds: string[];
            };
      },
      { input }
    ) => {
      return await withRpcClient(c =>
        c.oauth.action({
          ...data,
          clientId: input.clientId
        })
      );
    }
  }
});

export let useIntegration = integrationLoader.use;
