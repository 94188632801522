import { isServiceError } from '@lb-lib/error';
import { createClient } from '@lb-lib/rpc/client.mjs';
import type { OAuthClient } from '@loopbound-ares/rpc-core/clients/oauth';
import { getToken, probeAuth } from '@loopbound-ares/state';
import { config } from '../config';

export let rpcClient = (async () => {
  return () =>
    createClient<OAuthClient>({
      endpoint: config.urls.rpc,
      client: {
        name: config.product.id,
        version: 'latest'
      },
      getHeaders: async () => {
        let token = await getToken();

        return {
          Authorization: `Bearer ${token}`,
          'loopbound-product': config.product.id,
          'loopbound-service': 'integrations'
        };
      }
    });
})();

export let withRpcClient = async <T>(fn: (client: OAuthClient) => Promise<T>) => {
  try {
    let client = await rpcClient;
    return (await fn(client())) as T;
  } catch (e) {
    if (isServiceError(e) && e.data.status == 401) probeAuth();
    throw e;
  }
};
