import { LoopboundLogo } from '@lb-lib/ui';
import { createFrontendRouter } from '@loopbound-ares/microfrontend';
import { Outlet } from 'react-router-dom';
import { config } from './config';
import { productSlice } from './pages/routes';

export let App = createFrontendRouter({
  frontends: [productSlice('')],
  layout: (
    <>
      <Outlet />
    </>
  ),
  context: {
    product: config.product,

    logo: <LoopboundLogo />,

    search: {
      enabled: false
    },

    layout: {
      instanceSwitcher: false,

      horizontalSwitcher: {
        enabled: true
      }
    },

    unibar: {
      docsUrl: 'https://docs.loopbound.com'
    },

    services: {
      idHost: import.meta.env.VITE_ID_FRONTEND_URL,
      idApi: import.meta.env.VITE_SESSION_API_URL,

      coreApi: {
        userUrl: import.meta.env.VITE_USER_API_URL,
        fileUrl: import.meta.env.VITE_FILE_API_URL,
        utilsUrl: import.meta.env.VITE_UTILS_API_URL,
        instanceUrl: import.meta.env.VITE_INSTANCE_API_URL,
        organizationUrl: import.meta.env.VITE_ORGANIZATION_API_URL,
        billingUrl: import.meta.env.VITE_BILLING_API_URL,
        dashboardUrl: import.meta.env.VITE_DASHBOARD_API_URL,
        developerUrl: import.meta.env.VITE_DEVELOPER_API_URL
      },

      adminFrontendUrl: import.meta.env.VITE_INSTALL_FRONTEND_URL,
      accountFrontendUrl: `${import.meta.env.VITE_INSTALL_FRONTEND_URL}/account`,

      checkoutFrontendUrl: import.meta.env.VITE_CHECKOUT_URL
    }
  }
});
