import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  Link,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import { App } from './app';

import './reset.css';

(window as any).LinkComponent = Link;

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://7196fe296b294ee00d148ec5cf521d1c@o4505602264203264.ingest.us.sentry.io/4507726041972736',

    environment: import.meta.env.VITE_LOOPBOUND_ENV,

    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],

    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  });
}

try {
  ReactDOM.createRoot(document.getElementsByClassName('__loopbound_app__')[0], {
    onRecoverableError: Sentry.reactErrorHandler()
  }).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} catch (error) {
  Sentry.captureException(error);
}
